import { AdditionalServiceCategory } from 'modules/additionalServices/types/AdditionalServiceCategory';
import type { AdditionalService } from 'modules/additionalServices/types/AdditionalService';

const ADDITIONAL_SERVICES_CATEGORIES_ICONS: {
  [key in AdditionalServiceCategory]: string;
} = {
  [AdditionalServiceCategory.TemporaryHousing]: '/images/additional_services/couch_and_lamp.png',
  [AdditionalServiceCategory.PermanentHousing]: '/images/additional_services/house.png',
  [AdditionalServiceCategory.FamilySupport]: '/images/additional_services/family.png',
  [AdditionalServiceCategory.ConciergeServices]: '/images/additional_services/bellhop.png',
  [AdditionalServiceCategory.Moving]: '/images/additional_services/package.png',
};

export const getAdditionalServiceIcon = ({
  category,
}: Pick<AdditionalService, 'category'>): string => {
  return ADDITIONAL_SERVICES_CATEGORIES_ICONS[category];
};
