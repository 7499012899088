import { Anchor, Callout } from '@localyze-pluto/components';
import { theme } from '@localyze-pluto/theme';
import React from 'react';
import { PERMISSIONS_DOCUMENTATION_URL } from 'constants/permissions';

export const PermissionsCallout = (): React.JSX.Element => (
  <Callout style={{ marginBottom: theme.space.d5, width: 'fit-content' }}>
    Do you want to know more about your different user roles and permissions within each role?
    <Anchor href={PERMISSIONS_DOCUMENTATION_URL} isExternal style={{ display: 'block' }}>
      Learn more about user roles here.
    </Anchor>
  </Callout>
);
