import {
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalHeading,
  ModalFooter,
  Paragraph,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import { useTheme } from '@localyze-pluto/theme';
import React from 'react';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { humanizeGlomoRequestType } from 'modules/glomoRequests/utils/humanizeGlomoRequestType';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { useApproveGlomoAssessmentRequest } from 'modules/glomoRequests/api/useApproveGlomoAssessmentRequest/useApproveGlomoAssessmentRequest';

// TODO: should this component change to SubmitForAssessmentButton after the flag is removed?
export const ApproveForAssessmentButton = ({
  glomoRequest,
}: {
  glomoRequest: GlomoRequest;
}): React.JSX.Element => {
  const theme = useTheme();
  const toast = useToast();
  const modal = useModalStore({});

  const isRiskAssessmentActive = useIsFeatureFlagActive('workation_risk_assessment_result');

  const { mutate: approveGlomoAssessmentRequest } = useApproveGlomoAssessmentRequest({
    onSuccess: () => {
      modal.hide();
      toast('Request successfully approved for assessment', 'success');
    },
    onError: () => {
      toast('Something went wrong, please try again', 'error');
    },
  });

  return (
    <>
      <Modal store={modal} style={{ maxWidth: isRiskAssessmentActive ? '500px' : '400px' }}>
        {isRiskAssessmentActive && (
          <>
            <ModalHeader>
              <ModalHeading>Submit for risk assessment</ModalHeading>
            </ModalHeader>
            <ModalBody>
              <Box.div alignItems="center" display="flex" flexDirection="column" gap="d4">
                <Box.div textAlign="left">
                  <Paragraph color="colorTextStrongest" marginBottom="d0">
                    If you submit this request for risk assessment, the trip will be evaluated. Your
                    company will be billed 79€ in the next billing cycle.
                  </Paragraph>
                </Box.div>
              </Box.div>
            </ModalBody>
            <ModalFooter>
              <Button minWidth={120} onClick={modal.hide} variant="secondary">
                Cancel
              </Button>
              <Button
                minWidth={120}
                onClick={() => approveGlomoAssessmentRequest({ glomoRequestId: glomoRequest.id })}
                variant="primary"
              >
                Submit
              </Button>
            </ModalFooter>
          </>
        )}
        {!isRiskAssessmentActive && (
          <ModalBody>
            <Box.div alignItems="center" display="flex" flexDirection="column" gap="d4">
              <Box.div
                alignContent="center"
                backgroundColor="colorBackgroundInfo"
                borderRadius="borderRadius20"
                display="flex"
                justifyContent="center"
                padding="p2"
                w={36}
              >
                <Icon color="colorIconInfo" decorative icon="circle-check" />
              </Box.div>
              <Heading as="h2" marginBottom="m0" size="heading50">
                Approve for assessment
              </Heading>
              <Box.div textAlign="center">
                <Paragraph
                  style={{
                    fontSize: theme.fontSizes.fontSize30,
                    lineHeight: theme.lineHeights.lineHeight40,
                  }}
                >
                  By approving the request for risk assessment you are booking a{' '}
                  {humanizeGlomoRequestType[glomoRequest.type]} assessment through Localyze, which
                  will be invoiced to you in the next billing cycle.
                </Paragraph>
              </Box.div>
              <Box.div display="flex" gap="d3" justifyContent="center">
                <Button onClick={() => modal.hide()} variant="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={() => approveGlomoAssessmentRequest({ glomoRequestId: glomoRequest.id })}
                  variant="primary"
                >
                  Approve
                </Button>
              </Box.div>
            </Box.div>
          </ModalBody>
        )}
      </Modal>
      <Button
        leadingIcon={isRiskAssessmentActive ? undefined : 'circle-check'}
        onClick={() => modal.show()}
        variant="primary"
      >
        {isRiskAssessmentActive ? 'Submit for risk assessment' : 'Approve for assessment'}
      </Button>
    </>
  );
};
