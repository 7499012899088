import React, { useState } from 'react';
import { Box, Button, Select, SelectItemProps, useToast } from '@localyze-pluto/components';
import { useAdditionalServicePartners } from 'modules/additionalServices/api/useAdditionalServicePartners/useAdditionalServicePartners';
import type { AdditionalServicePartner } from 'modules/additionalServices/types/AdditionalServicePartner';
import { mapToSelectOptions } from 'modules/filters/helpers/mapToSelectOptions';
import { useUpdateCaseAdditionalService } from 'modules/additionalServices/api/useUpdateCaseAdditionalService/useUpdateCaseAdditionalService';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useForm } from 'react-hook-form';
import { DetailedCaseAdditionalService } from 'modules/additionalServices/api/useDetailedCaseAdditionalService/useDetailedCaseAdditionalService';

type AdditionalServicePartnersFormProps = {
  caseAdditionalService: DetailedCaseAdditionalService;
};

const transformAdditionalServicePartners = (
  additionalServicePartners: AdditionalServicePartner[],
): SelectItemProps[] =>
  mapToSelectOptions({ records: additionalServicePartners, valueProp: 'id', labelProp: 'name' });

export const AdditionalServicePartnersForm: React.FC<AdditionalServicePartnersFormProps> = ({
  caseAdditionalService,
}) => {
  const toast = useToast();
  const additionalServicePartner = caseAdditionalService.additional_service_partner;
  const { data: additionalServicesPartners } = useAdditionalServicePartners();
  const { mutate: updateCaseAdditionalService } = useUpdateCaseAdditionalService({
    onSuccess: () => {
      toast('Additional service partner saved correctly', 'success');
    },
    onError: () => {
      toast('Additional service partner not saved', 'error');
    },
  });
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const { getValues, register, setValue } = useForm({
    defaultValues: {
      servicePartners: additionalServicePartner?.id,
    },
  });

  const handleClick = () => {
    if (!formDisabled && getValues('servicePartners')) {
      updateCaseAdditionalService({
        caseAdditionalService,
        additionalServicePartnerId: Number(getValues('servicePartners')),
      });
      trackEvent('additional service: save partner', {
        partner_id: getValues('servicePartners'),
      });
    }
    setFormDisabled(!formDisabled);
  };

  return (
    <Box.form display=" flex" gap="d2" padding="p4">
      <Select
        defaultValue={String(additionalServicePartner?.id || '')}
        disabled={formDisabled}
        id="servicePartners"
        items={transformAdditionalServicePartners(additionalServicesPartners || [])}
        placeholder="Select one"
        setValue={(value) => {
          const filteredPartner = additionalServicesPartners?.find(
            (item) => item.id === Number(value),
          );
          setValue('servicePartners', filteredPartner?.id || undefined);
        }}
        {...register('servicePartners')}
      />
      <Button onClick={handleClick} type="button" variant="ghost">
        {formDisabled ? 'Edit' : 'Save'}
      </Button>
    </Box.form>
  );
};
