import axios from 'axios';
import { AdditionalServicePartner } from 'modules/additionalServices/types/AdditionalServicePartner';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const ADDITIONAL_SERVICE_PARTNERS_QUERY_KEY = 'additional-service-partners';

export const getAdditionalServicePartners = async (): Promise<AdditionalServicePartner[]> => {
  const { data } = await axios.get<AdditionalServicePartner[]>('/additional_service_partners');

  return data;
};

export const useAdditionalServicePartners = <
  Result = AdditionalServicePartner[],
>(): UseQueryResult<Result> => {
  return useQuery({
    queryKey: [ADDITIONAL_SERVICE_PARTNERS_QUERY_KEY],
    queryFn: getAdditionalServicePartners,
  });
};
