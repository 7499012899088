import React, { useEffect, useState } from 'react';
import { IntegrationsTip } from './components/IntegrationsTip/IntegrationsTip';
import { PageLayout } from 'modules/layout/components/PageLayout';

import {
  getStackOneIntegrationCardSettings,
  StackOneIntegration,
} from 'modules/integrations/hooks/getIntegrationCardSettings';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { useActiveCompanyHRISIntegration } from 'modules/integrations/hooks/useActiveCompanyHRISIntegration/useActiveCompanyHRISIntegration';
import {
  Anchor,
  Box,
  Button,
  ContentCard,
  ContentCardProps,
  InteractiveElementType,
  useDrawerStore,
  useModalStore,
  useToast,
} from '@localyze-pluto/components';
import { trackPageView } from 'modules/analytics/analytics';
import { SyncedDataDrawer } from './components/SyncedDataDrawer/SyncedDataDrawer';
import { useStackOneConnect } from './hooks/useStackOneConnect/useStackOneConnect';
import { useCompany } from 'modules/company/api/useCompany/useCompany';
import NewIntegrationImage from 'assets/img/integrations/logos-question.jpg';
import { ConfirmIntegrationModal } from './components/ConfirmIntegrationModal/ConfirmIntegrationModal';
import { StackOneCompanyIntegration, StackOneProvider } from './types/HRISCompanyIntegration';
import { useHRISFileCategories } from './hooks/useHRISFileCategories/useHRISFileCategories';
import { IntegrationFileCategoriesModal } from './components/IntegrationFileCategoriesModal/IntegrationFileCategoriesModal';
import { updateCompanyIntegration } from 'deprecated/api/integrations/updateCompanyIntegration';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCurrentUser } from 'config/CurrentUserContext';

type CommonCardProps = Pick<
  ContentCardProps,
  | 'background'
  | 'imageAlt'
  | 'imagePosition'
  | 'imageSrc'
  | 'interactiveElementType'
  | 'maxWidth'
  | 'text'
  | 'title'
>;

type Account = {
  id: string;
  providerName: StackOneProvider;
};

const HELP_URL =
  // eslint-disable-next-line max-len
  'https://help.localyze.com/en/articles/8899495-localyze-integrations-human-resources-information-systems-hris';

const QuestionCard = () => (
  <ContentCard
    background="inverse"
    callToAction="Request integration"
    href="https://forms.gle/Rj4cDNvPSfMTuQ4AA"
    imageAlt="Provider logos (Sap, BambooHR, Personio, Workday) and a question mark"
    imagePosition="top"
    imageSrc={NewIntegrationImage}
    interactiveElementType={InteractiveElementType.Button}
    target="_blank"
    text="Please request an integration below if you're using a different HRIS provider."
    title="Missing an integration?"
  />
);

const Integrations = ({
  stackOneIntegrations,
  onClickEdit,
  onClickActivate,
  activeIntegration,
  isLoading,
}: {
  stackOneIntegrations: StackOneIntegration[];
  onClickEdit: (integration: StackOneIntegration) => void;
  onClickActivate: (integration: StackOneIntegration) => void;
  activeIntegration?: StackOneCompanyIntegration;
  isLoading?: boolean;
}) => {
  return stackOneIntegrations.map((integration) => {
    const commonProps: CommonCardProps = {
      background: 'inverse',
      imageAlt: `${integration.label} logo`,
      imagePosition: 'top',
      imageSrc: integration.logoUrl,
      title: integration.label,
      text: `Import your talent easily from ${integration.label}.`,
    };

    const isActivated = activeIntegration?.provider_data.stackone_provider_name === integration.id;

    if (isActivated) {
      return (
        <ContentCard
          {...commonProps}
          callToAction={
            <Button
              fullWidth
              loading={isLoading}
              onClick={() => onClickEdit(integration)}
              variant="primary"
            >
              Edit
            </Button>
          }
          interactiveElementType={InteractiveElementType.Custom}
          key={integration.id}
        />
      );
    }

    return (
      <ContentCard
        {...commonProps}
        callToAction={
          <Button
            fullWidth
            loading={isLoading}
            onClick={() => onClickActivate(integration)}
            variant="secondary"
          >
            Activate
          </Button>
        }
        interactiveElementType={InteractiveElementType.Custom}
        key={integration.id}
      />
    );
  });
};

export const IntegrationsList = (): React.JSX.Element => {
  const [selectedIntegration, setSelectedIntegration] = useState<StackOneIntegration | undefined>();
  const [account, setAccount] = useState<Account | undefined>();
  const [isUpdatingIntegration, setIsUpdatingIntegration] = useState(false);

  const toast = useToast();

  const drawerState = useDrawerStore();
  const fileCategoriesModalStore = useModalStore();
  const confirmModalState = useModalStore();
  const isFileCategoriesModalOpen = fileCategoriesModalStore.useState('open');

  const { company_id: companyId } = useCurrentUser();

  const { data: company } = useCompany({ companyId });
  const { loading: isLoadingStackOne, activateIntegration } = useStackOneConnect();

  const {
    data: activeIntegration,
    isPending: isPendingActiveCompanyHRISIntegration,
    refetch: refetchActiveIntegration,
  } = useActiveCompanyHRISIntegration(companyId);

  const stackOneIntegrations = getStackOneIntegrationCardSettings(activeIntegration);

  const { data: fileCategoriesData } = useHRISFileCategories({
    params: {
      accountId: account?.id,
      providerName: account?.providerName,
    },
    enabled: !!account,
  });

  const onCloseIntegrationFileCategoriesModal = () => {
    if (!isUpdatingIntegration) {
      toast(
        'The integration setup failed. Please select a file category to complete the setup.',
        'error',
      );

      trackEvent('integrations: user has not completed the setup', {
        company_id: companyId,
        provider: activeIntegration?.provider_data.stackone_provider_name,
      });
    }

    setAccount(undefined);
  };

  const onConnect = (account: Account) => {
    fileCategoriesModalStore.show();
    setAccount(account);
  };

  const onClickActivate = (integration: StackOneIntegration) => {
    setSelectedIntegration(integration);

    if (activeIntegration) {
      activateIntegration(integration, onConnect);
    } else {
      confirmModalState.show();
    }
  };

  const onClickEdit = (integration: StackOneIntegration) => {
    setSelectedIntegration(integration);

    activateIntegration(integration, onConnect);
  };

  const onPickCategory = (fileId: string, label: string) => {
    if (account) {
      setIsUpdatingIntegration(true);

      updateCompanyIntegration(companyId, 'stackone', {
        stackone_account_id: account.id,
        stackone_provider_name: account.providerName,
        stackone_file_category_id: fileId,
        stackone_file_category_label: label,
      })
        .catch((_error) => toast('The integration could not be activated', 'error'))
        .then(() => {
          fileCategoriesModalStore.hide();
          setAccount(undefined);
          refetchActiveIntegration();
          toast('Integration successfully activated', 'success');
        })
        .finally(() => setIsUpdatingIntegration(false));
    }
  };

  useEffect(() => {
    trackPageView('Integrations');
  }, []);

  return (
    <PageLayout title="Integrations">
      <Box.div display="flex" flexDirection="column">
        <Box.div display="flex" justifyContent="flex-end" marginBottom="m6">
          <Button as={Anchor} href={HELP_URL} leadingIcon="info" variant="ghost">
            How to set up your integration
          </Button>
          <Button leadingIcon="circle-help" onClick={drawerState.show} variant="ghost">
            Synced data
          </Button>
        </Box.div>
        <IntegrationsTip activeIntegration={activeIntegration} />
        {isPendingActiveCompanyHRISIntegration ? (
          <ContainedLoadingState />
        ) : (
          <>
            <Box.div
              display="grid"
              gap="d4"
              gridTemplateColumns={{
                _: 'repeat(2, 1fr)',
                xl: 'repeat(auto-fill, minmax(200px, 300px))',
              }}
              justifyContent="center"
              justifyItems="center"
              minWidth="100%"
              py="p6"
            >
              <>
                <Integrations
                  activeIntegration={activeIntegration}
                  isLoading={isLoadingStackOne || isPendingActiveCompanyHRISIntegration}
                  onClickActivate={onClickActivate}
                  onClickEdit={onClickEdit}
                  stackOneIntegrations={stackOneIntegrations}
                />
                <QuestionCard />
                {selectedIntegration && company && !activeIntegration && (
                  <ConfirmIntegrationModal
                    currency={company.currency}
                    modalState={confirmModalState}
                    onCancel={() => setSelectedIntegration(undefined)}
                    onConfirm={() => activateIntegration(selectedIntegration, onConnect)}
                  />
                )}
              </>
            </Box.div>
          </>
        )}
        {isFileCategoriesModalOpen && (
          <IntegrationFileCategoriesModal
            fileCategories={fileCategoriesData?.file_categories}
            isLoading={isUpdatingIntegration}
            onClickSend={onPickCategory}
            onClose={onCloseIntegrationFileCategoriesModal}
            providerName={account?.providerName}
            store={fileCategoriesModalStore}
          />
        )}
      </Box.div>
      <SyncedDataDrawer integration={activeIntegration} state={drawerState} />
    </PageLayout>
  );
};
