import { Theme } from '@xstyled/styled-components';
import { GlomoRequestRisk } from 'modules/glomoRequests/api/types/GlomoRequestRisk';
import { GlomoRequestStatus } from 'modules/glomoRequests/api/types/GlomoRequestStatus';
import { GlomoRequestType } from 'modules/glomoRequests/api/types/GlomoRequestType';

export const GLOMO_REQUEST_RISK_LEVEL: Record<GlomoRequestRisk, string> = {
  low: 'Low risk',
  medium: 'Mid risk',
  high: 'High risk',
};

export const GLOMO_REQUEST_RISK_COLOR: Record<
  GlomoRequestRisk,
  { background: keyof Theme['colors']; dot: keyof Theme['colors'] }
> = {
  low: { background: 'colorBackgroundSuccess', dot: 'colorIconSuccess' },
  medium: { background: 'colorBackgroundWarning', dot: 'colorIconWarning' },
  high: { background: 'colorBackgroundError', dot: 'colorTextError' },
};

export const RISK_LEVELS_SELECT_ITEMS: Array<{ label: string; value: GlomoRequestRisk | '' }> = [
  {
    label: 'All levels',
    value: '',
  },
  {
    label: GLOMO_REQUEST_RISK_LEVEL.low,
    value: 'low',
  },
  {
    label: GLOMO_REQUEST_RISK_LEVEL.medium,
    value: 'medium',
  },
  {
    label: GLOMO_REQUEST_RISK_LEVEL.high,
    value: 'high',
  },
];

export const GLOMO_REQUEST_STATUS_SELECT_ITEMS: Array<{
  label: string;
  value: GlomoRequestStatus | '';
}> = [
  {
    label: 'All statuses',
    value: '',
  },
  {
    label: 'Assessment complete',
    value: 'complete',
  },
  {
    label: 'Denied',
    value: 'denied',
  },
  {
    label: 'In assessment',
    value: 'in_assessment',
  },
  {
    label: 'Pending approval',
    value: 'pending_approval',
  },
];

export const GLOMO_REQUEST_NEW_STATUS_SELECT_ITEMS: Array<{
  label: string;
  value: GlomoRequestStatus | '';
}> = [
  {
    label: 'All statuses',
    value: '',
  },
  {
    label: 'Trip approved',
    value: 'request_approved',
  },
  {
    label: 'Trip denied',
    value: 'request_denied',
  },
  {
    label: 'Risk assessment denied',
    value: 'risk_assessment_denied',
  },
  {
    label: 'Risk assessment in progress',
    value: 'risk_assessment_in_progress',
  },
  {
    label: 'Risk assessment in review',
    value: 'risk_assessment_in_review',
  },
  {
    label: 'Pending assessment approval',
    value: 'pending_assessment_approval',
  },
];

export const GLOMO_REQUEST_TYPE_ITEMS: Array<{
  label: string;
  value: GlomoRequestType | '';
}> = [
  {
    label: 'All request types',
    value: '',
  },
  {
    label: 'Workation',
    value: 'WorkationAssessment',
  },
  {
    label: 'Business trip',
    value: 'BusinessTripAssessment',
  },
];

export type GlomoRequestFilterCommonKey = 'risk_level' | 'status' | 'type';
