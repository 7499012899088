import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryClient } from 'config/queryClient';
import { getStatusUpdatesQueryKey } from 'modules/statusUpdates/api/useStatusUpdates/useStatusUpdates';
import {
  DETAILED_CASE_ADDITIONAL_SERVICE_QUERY_KEY,
  DetailedCaseAdditionalService,
} from 'modules/additionalServices/api/useDetailedCaseAdditionalService/useDetailedCaseAdditionalService';
import { CASE_ADDITIONAL_SERVICES_QUERY_KEY } from 'modules/additionalServices/api/useCaseAdditionalServices/useCaseAdditionalServices';

type UpdateCaseAdditionalServiceProps = {
  caseAdditionalService: DetailedCaseAdditionalService;
  additionalServicePartnerId: number;
};

async function updateCaseAdditionalServices({
  caseAdditionalService,
  additionalServicePartnerId,
}: UpdateCaseAdditionalServiceProps) {
  const response = await axios.patch(`/case_additional_services/${caseAdditionalService.id}`, {
    additional_service_partner_id: additionalServicePartnerId,
  });

  return response;
}

export const useUpdateCaseAdditionalService = ({
  onSuccess,
  onError,
}: Pick<
  UseMutationOptions<unknown, AxiosError, UpdateCaseAdditionalServiceProps>,
  'onError' | 'onSuccess'
> = {}): UseMutationResult<unknown, AxiosError, UpdateCaseAdditionalServiceProps> =>
  useMutation({
    mutationFn: ({ caseAdditionalService, additionalServicePartnerId }) => {
      return updateCaseAdditionalServices({
        caseAdditionalService,
        additionalServicePartnerId,
      });
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getStatusUpdatesQueryKey({
          subjectType: 'CaseAdditionalService',
          subjectId: variables.caseAdditionalService.id,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: [
          DETAILED_CASE_ADDITIONAL_SERVICE_QUERY_KEY,
          String(variables.caseAdditionalService.id),
        ],
      });
      await queryClient.invalidateQueries({
        queryKey: [
          CASE_ADDITIONAL_SERVICES_QUERY_KEY,
          String(variables.caseAdditionalService.case_id),
        ],
      });

      onSuccess?.(data, variables, context);
    },
    onError,
  });
