import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  ContentCard,
  InteractiveElementType,
} from '@localyze-pluto/components';
import { useReferralCard } from 'modules/home/api/useReferralCard';
import { useIsFeatureFlagActive } from 'modules/featureFlags/hooks/useIsFeatureFlagActive/useIsFeatureFlagActive';
import { isBannerVisible } from '../utils/isBannerVisible';
import { fullName } from 'modules/users/utils/mapUsersToSelectOptions';
import { trackEvent } from 'deprecated/utils/helper/segment';
import { useCurrentUser } from 'config/CurrentUserContext';

const bannerLastDateKey = 'homePageBanner.lastDate';

export const ReferralCampaign = ({
  isDismissible = false,
  marginBottom,
}: {
  isDismissible?: boolean;
  marginBottom?: BoxProps['marginBottom'];
}): React.ReactNode => {
  const user = useCurrentUser();
  const { data } = useReferralCard();
  const isFeatureFlagActive = useIsFeatureFlagActive('referral_campaign');
  const activeCards = data?.filter((card) => card.active);
  const cardData = activeCards?.[0];
  const [lastBannerDate, setLastBannerDate] = useState<string | null>(
    localStorage.getItem(bannerLastDateKey),
  );

  const isVisible = isBannerVisible({
    isFeatureFlagActive,
    isActive: cardData?.active,
    lastBannerDate,
    updatedAt: cardData?.sys?.updatedAt,
  });

  if (!isVisible || !cardData) {
    return null;
  }

  const referralLink = new URL(cardData.buttonUrl);

  const curentUserEmail = user.email || '';

  referralLink.searchParams.append('referrer_s_email_address', curentUserEmail);
  referralLink.searchParams.append('referrer_s_name', fullName(user));

  const onDismiss = () => {
    const updatedAt = cardData.sys?.updatedAt;

    trackEvent('cases dashboard: click on remove banner', {
      user_role: user.user_role,
      company_id: user.company_id,
      hr_dashboard_section: 'cases at risk',
    });

    if (updatedAt) {
      localStorage.setItem(bannerLastDateKey, updatedAt);
      setLastBannerDate(updatedAt);
    }
  };

  return (
    <Box.div display="flex" justifyContent="center" marginBottom={marginBottom}>
      <Box.div position={'relative'}>
        <ContentCard
          background="inverse"
          callToAction={cardData.buttonText}
          href={referralLink.href}
          imageAlt={cardData.image.fields.description}
          imageSrc={cardData.image.fields.file.url}
          interactiveElementType={InteractiveElementType.Button}
          target="_blank"
          text={cardData.text}
          title={cardData.heading}
        />
        {isDismissible && (
          <Box.div position="absolute" right={8} top={8}>
            <Button
              aria-label="Dismiss banner"
              backgroundColor="transparent"
              iconOnly
              leadingIcon="x"
              onClick={() => {
                onDismiss();
              }}
              type="button"
              variant="ghost"
            />
          </Box.div>
        )}
      </Box.div>
    </Box.div>
  );
};
