import includes from 'lodash/includes';
import { AdditionalServiceCategory } from '../types/AdditionalServiceCategory';
import { CaseType } from 'modules/cases/types/CaseTypeEnum';

const allowedCaseTypes = [CaseType.FullRelocation, CaseType.VisaOnly, CaseType.DomesticRelocation];

export const isAdditionalServiceCategoryRecommended = (
  additionalServiceCategory: AdditionalServiceCategory,
  caseTypeName?: CaseType,
): boolean => {
  if (additionalServiceCategory === AdditionalServiceCategory.TemporaryHousing) {
    return includes(allowedCaseTypes, caseTypeName);
  }

  return false;
};
