import * as Yup from 'yup';
import { Validation } from 'constants/formValidations';

const fieldRequired = 'This field is required.';
const specifyAction = 'Please specify, why this action is required.';

export const riskAssessmentResultValidationSchema = Yup.object().shape({
  riskLevel: Yup.string().required(fieldRequired),
  riskAssessmentUrl: Yup.string().url('Invalid URL format.').required(fieldRequired),
  report_pdf: Yup.string().required(fieldRequired),
  risksAndWarnings: Yup.array()
    .of(
      Yup.object().shape({
        description: Validation.requiredString,
        risk_type: Yup.string().required(),
      }),
    )
    .required(),
  a1_exemption_certificate: Yup.boolean().required(),
  certificate_of_cover: Yup.boolean().required(),
  documentation: Yup.boolean().required(),
  employers_certificate: Yup.boolean().required(),
  express_processing: Yup.boolean().required(),
  request_a1_certificate: Yup.boolean().required(),
  visa: Yup.boolean().required(),
  consultation: Yup.boolean().required(),
  consultation_text: Yup.string().when('consultation', {
    is: true,
    then: () => Yup.string().required(specifyAction),
  }),
  other: Yup.boolean().required(),
  other_text: Yup.string().when('other', {
    is: true,
    then: () => Yup.string().required(specifyAction),
  }),
});
