import { Badge, BadgeProps, IconProps } from '@localyze-pluto/components';
import React from 'react';
import { GlomoRequestStatus } from '../api/types/GlomoRequestStatus';

const badgeData: Record<
  GlomoRequestStatus,
  { text: string; icon?: IconProps['icon']; color: BadgeProps['color'] }
> = {
  pending_approval: { text: 'Pending approval', color: 'blue' },
  in_assessment: { text: 'In assessment', color: 'blue' },
  complete: { text: 'Assessment complete', color: 'green' },
  denied: { text: 'Denied', color: 'red' },

  pending_assessment_approval: { text: 'Pending assessment approval', color: 'blue' },
  request_approved: { text: 'Trip approved', color: 'green', icon: 'circle-check' },
  request_denied: { text: 'Trip denied', color: 'red', icon: 'circle-x' },
  risk_assessment_denied: { text: 'Risk assessment denied', color: 'red', icon: 'circle-x' },
  risk_assessment_in_progress: { text: 'Risk assessment in progress', color: 'gray' },
  risk_assessment_in_review: { text: 'Risk assessment in review', color: 'blue' },
};

export const GlomoRequestStatusBadge = ({
  status,
}: {
  status: GlomoRequestStatus;
}): React.JSX.Element => {
  const { color, icon, text } = badgeData[status];

  return (
    <Badge color={color} icon={icon} style={{ whiteSpace: 'nowrap' }}>
      {text}
    </Badge>
  );
};
