import { Button, useModalStore, useToast } from '@localyze-pluto/components';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { useDenyGlomoAssessmentRequest } from 'modules/glomoRequests/api/useDenyGlomoAssessmentRequest/useDenyGlomoAssessmentRequest';
import React from 'react';
import { DenyRequestModal } from '../DenyRequestModal/DenyRequestModal';

export const DenyRequestButton = ({
  glomoRequest,
  isRiskAssessmentActive = false,
}: {
  glomoRequest: GlomoRequest;
  // TODO: remove this prop once workation_risk_assessment_result flag is removed
  isRiskAssessmentActive?: boolean;
}): React.JSX.Element => {
  const toast = useToast();
  const modal = useModalStore({});

  const { mutate: denyGlomoRequest } = useDenyGlomoAssessmentRequest({
    onSuccess: () => {
      modal.hide();
      toast('Request denied', 'success');
    },
    onError: () => {
      toast('Something went wrong, please try again', 'error');
    },
  });

  return (
    <>
      <DenyRequestModal
        onClickDeny={(denialReason) => {
          denyGlomoRequest({
            glomoRequestId: glomoRequest.id,
            denialReason,
          });
        }}
        store={modal}
      />
      <Button
        leadingIcon={isRiskAssessmentActive ? undefined : 'circle-x'}
        onClick={() => modal.show()}
        variant="secondary"
      >
        {isRiskAssessmentActive ? 'Deny request' : 'Deny'}
      </Button>
    </>
  );
};
