import { activeLinkClasses, commonLinkClasses } from './styles';

import { LinkProps } from './types/LinkProps';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { Box } from '@localyze-pluto/components';

export const Link = ({
  to,
  label,
  icon,
  onClick,
  counterValue,
  exact,
}: LinkProps): React.JSX.Element =>
  to.includes('/administration') ? (
    <Box.a className={commonLinkClasses} href={to}>
      <Box.span alignItems="center" display="flex">
        {icon}
        {label}
      </Box.span>
    </Box.a>
  ) : (
    <NavLink
      activeClassName={activeLinkClasses}
      aria-label={counterValue ? `${label}: ${counterValue} pending items` : label}
      className={commonLinkClasses}
      exact={exact}
      onClick={onClick}
      to={to}
    >
      <Box.span display="flex">
        {icon}
        {label}
      </Box.span>
      {counterValue !== undefined && (
        <Box.div
          aria-hidden
          backgroundColor="bgInfo"
          borderRadius="borderRadiusPill"
          color="badgeContentInfo"
          fontSize="fontSize10"
          minWidth={32}
          px="d2"
          py="d0_5"
          textAlign="center"
        >
          {' '}
          {counterValue}
        </Box.div>
      )}
    </NavLink>
  );
