import { IconProps } from '@localyze-pluto/components';
import { StatusStepperStep } from 'components/StatusStepper/StatusStepper';
import { GlomoRequestStatus } from 'modules/glomoRequests/api/types/GlomoRequestStatus';

const submit: StatusStepperStep = { text: 'Submit for risk assessment' };
const inProgress: StatusStepperStep = { text: 'Risk assessment in progress' };
const review: StatusStepperStep = { text: 'Review risk assessment results' };
const approveOrDeny: StatusStepperStep = { text: 'Approve or deny trip' };

const states: Record<GlomoRequestStatus, StatusStepperStep['status'][]> = {
  pending_approval: [],
  complete: [],
  denied: [],
  in_assessment: [],

  pending_assessment_approval: ['ongoing'],
  risk_assessment_in_progress: ['complete', 'ongoing'],
  risk_assessment_denied: ['complete'],
  risk_assessment_in_review: ['complete', 'complete', 'ongoing'],
  request_approved: ['complete', 'complete', 'complete', 'complete'],
  request_denied: ['complete', 'complete', 'complete', 'complete'],
};

const icons: Record<GlomoRequestStatus, (IconProps['icon'] | undefined)[]> = {
  pending_approval: [],
  complete: [],
  denied: [],
  in_assessment: [],

  pending_assessment_approval: [],
  risk_assessment_in_progress: [],
  risk_assessment_denied: ['x'],
  risk_assessment_in_review: [],
  request_approved: [],
  request_denied: [undefined, undefined, undefined, 'x'],
};

// Applies StatusStepperStep['status'] from "states" to each step according GlomoRequestStatus
export const getStatusSteps = (status: GlomoRequestStatus): StatusStepperStep[] => {
  const steps = [submit, inProgress, review, approveOrDeny];

  return steps.map((step, index) => {
    return {
      ...step,
      status: states[status][index],
      icon: icons[status][index],
    };
  });
};
