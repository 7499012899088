import React, { ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Heading,
  Icon,
  Paragraph,
  Tooltip,
  TooltipAnchor,
  useTooltipStore,
} from '@localyze-pluto/components';
import {
  CHECK_ITEMS,
  ITEMS_TOOLTIP_TEXT,
  RequiredActionId,
} from '../RequiredActions/RequiredActions';
import { RequiredActionItem } from 'modules/glomoRequests/types/RequiredActionItem';

const LIST_ITEMS_TEXT: Record<RequiredActionId, string> = {
  request_a1_certificate: 'Request A1 certificate',
  a1_exemption_certificate: 'Request A1 exemption certificate',
  certificate_of_cover: 'Request certificate of cover',
  express_processing: 'Request express processing',
  visa: 'Apply for visa',
  documentation: 'Request documentation',
  employers_certificate: "Request employer's certificate",
  consultation: 'Request live consultation with expert',
  consultation_text: '',
  other: 'Other',
  other_text: '',
};

const CheckItem = ({ item, children }: { item: RequiredActionItem; children?: ReactNode }) => {
  const tooltip = useTooltipStore({
    showTimeout: 0,
    placement: 'right',
  });

  return (
    <Box.li
      aria-label={LIST_ITEMS_TEXT[item.action]}
      display="flex"
      flexDirection="column"
      gap="d1"
      key={item.action}
      role="listitem"
      w="100%"
    >
      <Box.div alignItems="center" display="flex" gap="d1">
        <Icon color="colorIconSuccess" decorative icon="check" size="sizeIcon30" />
        <Box.span fontSize="fontSize20">{LIST_ITEMS_TEXT[item.action]}</Box.span>
        {ITEMS_TOOLTIP_TEXT[item.action] && (
          <>
            <TooltipAnchor render={<Box.div h={16} />} store={tooltip}>
              <Icon
                color="contentSecondary"
                decorative={false}
                h={16}
                icon="info"
                size="sizeIcon20"
                title={`${item.value} information`}
              />
            </TooltipAnchor>
            <Tooltip store={tooltip}>{ITEMS_TOOLTIP_TEXT[item.action]}</Tooltip>
          </>
        )}
      </Box.div>
      {children}
    </Box.li>
  );
};

const TextItemCallout = ({
  action,
  textAction,
  requiredActions,
  title,
}: {
  action: RequiredActionId;
  textAction: RequiredActionId;
  requiredActions: RequiredActionItem[];
  title: string;
}) => {
  const item = requiredActions.find((item) => item.action === action);

  if (!item || !item.value) {
    return null;
  }

  const textItem = requiredActions.find((item) => item.action === textAction);

  return (
    <CheckItem item={item}>
      <Box.div
        backgroundColor="bgInfo"
        borderRadius="borderRadius20"
        lineHeight="lh4"
        marginLeft="d6"
        padding="d4"
      >
        <Box.strong color="contentPrimary" fontSize="fontSize10">
          {title}
        </Box.strong>
        <Paragraph fontSize="fontSize10" marginBottom="m0">
          {textItem?.value || ''}
        </Paragraph>
      </Box.div>
    </CheckItem>
  );
};

export const RequiredActionsList = ({
  requiredActions,
  title = 'Required actions',
  ...props
}: BoxProps & {
  title?: string;
  requiredActions: RequiredActionItem[];
}): React.JSX.Element => {
  return (
    <Box.div marginBottom="d8" paddingLeft="d5" {...props}>
      {title && (
        <Heading
          as="h4"
          color="contentPrimary"
          fontSize="titleBody"
          fontWeight="fontWeightBold"
          lineHeight="lh6"
          marginBottom="d2"
        >
          {title}
        </Heading>
      )}
      <Box.ul
        display="flex"
        flexDirection="column"
        gap="d2"
        listStyleType="none"
        margin="d0"
        marginBottom="d2"
        padding="d0"
      >
        {requiredActions
          .filter(({ action, value }) => !!value && CHECK_ITEMS.includes(action))
          .map((item) => (
            <CheckItem item={item} key={item.action} />
          ))}
        <TextItemCallout
          action="consultation"
          requiredActions={requiredActions}
          textAction="consultation_text"
          title="Why is a consultation needed?"
        />
        <TextItemCallout
          action="other"
          requiredActions={requiredActions}
          textAction="other_text"
          title="Other"
        />
      </Box.ul>
    </Box.div>
  );
};
