import { Box, Callout, Paragraph } from '@localyze-pluto/components';
import { GlomoRequest } from 'modules/glomoRequests/api/types/GlomoRequest';
import { humanizeGlomoRequestType } from 'modules/glomoRequests/utils/humanizeGlomoRequestType';
import React from 'react';
import { formatDate } from 'utils/formatters/formatDate/formatDate';

export const HRCallout = ({ glomoRequest }: { glomoRequest: GlomoRequest }): React.JSX.Element => {
  const glomoRequestType = humanizeGlomoRequestType[glomoRequest.type];

  return (
    <>
      {glomoRequest.status === 'pending_approval' && (
        <Callout variant="information">
          <Paragraph fontSize="fontSize20" marginBottom="d0">
            Please compare the request details with your company&apos;s {glomoRequestType} policy.
            Deny or approve the request for risk assessment if it meets the necessary criteria.
            Please be aware,{' '}
            <Box.strong>
              the risk assessment typically takes 5-7 business days, depending on the complexity of
              the assessment.
            </Box.strong>
          </Paragraph>
        </Callout>
      )}
      {glomoRequest.status === 'in_assessment' && (
        <Callout variant="information">
          <Paragraph fontSize="fontSize20" marginBottom="d0">
            Please be aware, the{' '}
            <Box.strong>
              risk assessment typically takes 5-7 business days or longer if a more complex
              assessment is required.
            </Box.strong>
          </Paragraph>
        </Callout>
      )}
      {glomoRequest.status === 'denied' && (
        <Callout variant="error">
          <Paragraph fontSize="fontSize20" marginBottom="d0">
            {glomoRequest.denial_reason || ''}
          </Paragraph>
        </Callout>
      )}
      {glomoRequest.status === 'complete' && (
        <Callout variant="information">
          <Paragraph fontSize="fontSize20" marginBottom="d0">
            <Box.strong>What&apos;s next?</Box.strong>
            <Box.br />
            The risk assessment for this {glomoRequestType} request is completed. If you want to
            continue the process, please open the risk assessment and click on the featured links to
            trigger the next steps. By clicking these links, additional costs for the{' '}
            {glomoRequestType} case will occur and invoiced to you in the next billing cycle.
          </Paragraph>
        </Callout>
      )}
      {glomoRequest.status === 'pending_assessment_approval' && (
        <Callout variant="information">
          <Paragraph color="calloutBodyInfo" fontSize="fontSize20" marginBottom="d0">
            If you submit this request for risk assessment, the trip will be evaluated within
            <Box.strong> 3-5 business days</Box.strong> for the cost of 79€. If you deny the
            request, no further action will be taken.
          </Paragraph>
        </Callout>
      )}

      {glomoRequest.status === 'risk_assessment_in_progress' && (
        <Callout variant="information">
          <Paragraph color="calloutBodyInfo" fontSize="fontSize20" marginBottom="d0">
            This trip was submitted for risk assessment on{' '}
            <Box.strong>{formatDate(glomoRequest.created_at)}</Box.strong>. The results of the risk
            assessment will be available within <Box.strong>3-5 business days</Box.strong>.
          </Paragraph>
        </Callout>
      )}
    </>
  );
};
