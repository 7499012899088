import { Box, ControlledFormInput, ControlledFormSelect } from '@localyze-pluto/components';
import { RISK_LEVELS_SELECT_ITEMS } from 'modules/glomoRequests/constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const UpdateRiskLevelAndLinkToRiskAssessment = ({
  isEditMode,
}: {
  isEditMode?: boolean;
}): React.JSX.Element => {
  const { control } = useFormContext();

  return (
    <Box.form alignItems="flex-start" display="flex" gap="d4" w="100%">
      <Box.div w="100%">
        <ControlledFormSelect
          control={control}
          disabled={!isEditMode}
          id="riskLevel"
          items={RISK_LEVELS_SELECT_ITEMS}
          label="Risk level"
          name="riskLevel"
          required={isEditMode}
        />
      </Box.div>
      <Box.div w="100%">
        <ControlledFormInput
          control={control}
          disabled={!isEditMode}
          id="riskAssessmentUrl"
          label="Link to risk assessment"
          name="riskAssessmentUrl"
          required={isEditMode}
          type="text"
        />
      </Box.div>
    </Box.form>
  );
};
