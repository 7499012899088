import { differenceInDays } from 'date-fns';
import { GlomoRequestStatus } from '../api/types/GlomoRequestStatus';

type UserType = 'admin' | 'hr';

type HrProps = {
  userType: 'hr';
  status: GlomoRequestStatus;
  date?: undefined;
  now?: undefined;
};

type AdminProps = {
  userType: 'admin';
  status: GlomoRequestStatus;
  date: string;
  now?: string;
};

const statusesPerUser: Record<UserType, GlomoRequestStatus[]> = {
  admin: ['risk_assessment_in_progress', 'request_approved'],
  hr: ['pending_assessment_approval', 'pending_approval', 'risk_assessment_in_review'],
};

export const shouldShowNotification = ({
  userType,
  status,
  date,
  now = new Date().toISOString(),
}: AdminProps | HrProps): boolean => {
  if (userType === 'admin' && status === 'risk_assessment_in_progress') {
    return true;
  }

  if (userType === 'admin') {
    const difference = differenceInDays(new Date(now), new Date(date));

    return difference <= 5 && statusesPerUser['admin'].includes(status);
  }

  return statusesPerUser['hr'].includes(status);
};
