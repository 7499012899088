import { NEW_TEAM_MEMBER, TEAM_TITLE } from './constants';
import React, { useEffect } from 'react';
import { ErrorStatePage } from 'components/ErrorStatePage/ErrorStatePage';
import { PageLayout } from 'modules/layout/components/PageLayout';
import { TeamTable } from './components/TeamTable/TeamTable';
import { PermissionsCallout } from 'modules/company/components/PermissionsCallout/PermissionsCallout';
import { ContainedLoadingState } from 'components/ContainedLoadingState/ContainedLoadingState';
import { AddTeamMember } from 'modules/company/components/AddTeamMember/AddTeamMember';
import { useCompanyUsers } from 'modules/company/api/useCompanyUsers/useCompanyUsers';
import { useParams } from 'react-router-dom';
import { Box, Button, useModalStore } from '@localyze-pluto/components';

import { trackPageView } from 'modules/analytics/analytics';
import { useCurrentUser } from 'config/CurrentUserContext';

export const Team = (): React.JSX.Element => {
  const { company_id, isAdmin, isHrAdmin } = useCurrentUser();
  const addMemberModalState = useModalStore();
  const { id } = useParams<{ id: string }>();

  const companyId = id ? Number(id) : company_id;

  const {
    data: teamMembers,
    isSuccess: isTeamSuccess,
    error: isTeamError,
    refetch: refetchTeam,
  } = useCompanyUsers({ params: { companyId } });

  useEffect(() => {
    trackPageView('Team');
  }, []);

  if (isTeamError || !companyId) {
    return <ErrorStatePage />;
  }

  if (!isTeamSuccess) {
    return <ContainedLoadingState />;
  }

  const hasEditPermissions = isAdmin || isHrAdmin;

  return (
    <PageLayout title={TEAM_TITLE}>
      <Box.div display="flex" flexDirection="column" gap="d2" marginBottom="m4" marginTop="m1">
        <Box.div>
          {hasEditPermissions && (
            <Button
              className="tw-float-right"
              leadingIcon="plus"
              onClick={addMemberModalState.show}
              variant="secondary"
            >
              {NEW_TEAM_MEMBER}
            </Button>
          )}
        </Box.div>

        <TeamTable canEdit={hasEditPermissions} companyId={companyId} teamMembers={teamMembers} />
      </Box.div>
      <AddTeamMember companyId={companyId} onSubmit={refetchTeam} state={addMemberModalState} />
      <PermissionsCallout />
    </PageLayout>
  );
};
