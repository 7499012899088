import { Box, BoxProps, Heading, Icon, IconProps, Paragraph } from '@localyze-pluto/components';
import { SectionCard } from 'components/SectionCard/SectionCard';
import { GlomoRequestStatus } from 'modules/glomoRequests/api/types/GlomoRequestStatus';
import { UserType } from 'modules/users/types/UserType';

import React from 'react';

type Status = Extract<
  GlomoRequestStatus,
  'request_approved' | 'request_denied' | 'risk_assessment_denied'
>;

const TITLE: Record<Status, string> = {
  request_approved: 'Trip approved',
  request_denied: 'Trip denied',
  risk_assessment_denied: 'Risk assessment denied',
};

const ICON_PROPS: Record<Status, { color: IconProps['color']; icon: IconProps['icon'] }> = {
  request_approved: { color: 'iconWrapperContentSuccess', icon: 'circle-check' },
  request_denied: { color: 'iconWrapperContentDestructive', icon: 'circle-alert' },
  risk_assessment_denied: { color: 'iconWrapperContentDestructive', icon: 'circle-alert' },
};

const ICON_BACKGROUND: Record<Status, BoxProps['backgroundColor']> = {
  request_approved: 'iconWrapperBgSuccess',
  request_denied: 'iconWrapperBgDestructive',
  risk_assessment_denied: 'iconWrapperBgDestructive',
};

const TEXT: Record<Status, { [key in Exclude<UserType, 'international'>]: string }> = {
  request_approved: {
    admin:
      'Please take the required actions to ensure the talent’s trip meets compliance requirements.',
    // eslint-disable-next-line max-len
    hr: 'Localyze will now take the required actions to ensure your talent’s trip meets compliance requirements. We will get in touch with you if we need further information.',
  },
  request_denied: {
    admin: 'The HR manager denied the trip. There are no further actions required from our side.',
    hr: 'You denied this trip which means no further action will be taken.',
  },
  risk_assessment_denied: {
    admin:
      // eslint-disable-next-line max-len
      'The HR manager denied the risk assessment. There are no further actions required from our side.',
    hr: 'You denied this risk assessment which means no further action will be taken.',
  },
};

export const TripApprovalResult = ({
  status,
  userType,
  ...props
}: BoxProps & {
  status: Status;
  userType: 'admin' | 'hr';
}): React.JSX.Element => {
  return (
    <SectionCard {...props}>
      <SectionCard.Content>
        <Box.div alignItems="center" display="flex" flexDirection="column" justifyContent="center">
          <Box.div
            backgroundColor={ICON_BACKGROUND[status]}
            borderRadius="borderRadius30"
            boxSizing="border-box"
            h={40}
            marginBottom="d4"
            padding="d2"
            w={40}
          >
            <Icon decorative size="sizeIcon40" {...ICON_PROPS[status]} />
          </Box.div>
          <Heading as="h5" color="contentPrimary" marginBottom="d2" size="title-group">
            {TITLE[status]}
          </Heading>
          <Paragraph marginBottom="d0" maxW={680} textAlign="center">
            {TEXT[status][userType]}
          </Paragraph>
        </Box.div>
      </SectionCard.Content>
    </SectionCard>
  );
};
