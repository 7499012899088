import React from 'react';
import { Box, Select, SelectItemProps } from '@localyze-pluto/components';

export type Filter<Key> = {
  id: string;
  items: SelectItemProps[];
  value?: SelectItemProps['value'];
  placeholder: string;
  ariaLabel: string;
  key: Key;
};

type GlomoRequestFiltersProps<Key> = {
  filters: Filter<Key>[];
  onChange: (key: Key, value: SelectItemProps['value']) => void;
};

export const GlomoRequestFilters = <Key,>({
  filters,
  onChange,
}: GlomoRequestFiltersProps<Key>): React.ReactNode => {
  return (
    <>
      <Box.span fontSize="fontSize20">Filter by</Box.span>
      {filters.map((filter) => (
        <Box.div key={filter.id} minWidth={185}>
          <Select
            aria-label={filter.ariaLabel}
            id={filter.id}
            items={filter.items}
            placeholder={filter.placeholder}
            setValue={(value) => onChange(filter.key, value as SelectItemProps['value'])}
            value={filter.value || ''}
          />
        </Box.div>
      ))}
    </>
  );
};
